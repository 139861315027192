import * as React from 'react';

function DownGreyArrow(props) {
  return (
    <svg
      width={12}
      height={13}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M5.75 11.583A4.792 4.792 0 105.75 2a4.792 4.792 0 000 9.583z"
        stroke={props.color || '#433A4E'}
        strokeWidth={0.821429}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.059 5.896L5.75 7.583l1.692-1.687"
        stroke={props.color || '#433A4E'}
        strokeWidth={0.821429}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DownGreyArrow;
