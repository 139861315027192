import {Box, Modal} from '@mui/material';
import React from 'react';
import Colors from '../Resources/COLORS/Colors';
import {useDispatch} from 'react-redux';
import Actions from '../redux/Actions';
import {useTranslation} from 'react-i18next';
import Utility from '../commonComponents/Utility';
import {useAppNavigation} from '../hooks';

const SuccessModal = ({visible, patient_id, res}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {goToFullSyChart} = useAppNavigation();

  const okay = () => {
    localStorage.removeItem('questionaryId');
    goToFullSyChart(res, {id: patient_id});
    dispatch(
      Actions.setPatientStatus({
        id: patient_id,
        status: 'completed',
      }),
    );
    dispatch(Actions.updateSychartCount());
  };
  return (
    <Modal
      open={visible}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Box
        sx={{
          backgroundColor: 'white',
          width: '300px',
          height: '230px',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px 25px',
          flexDirection: 'column',
        }}>
        <h2
          style={{
            textAlign: 'center',
          }}>
          {Utility.isPatient
            ? t('your_response_has_sent')
            : 'Your SyChart has been recorded in the system.'}
        </h2>
        <div
          className="pointer"
          onClick={okay}
          style={{
            padding: '5px 30px',
            borderRadius: '20px',
            backgroundColor: Colors.APPCIRCLE,
            marginTop: '20px',
          }}>
          <p
            style={{
              color: 'white',
              fontSize: '20px',
              fontWeight: 'bold',
            }}>
            {/* Your SyChart has been successfully submitted. You will be notified
            once your SyChart is reviewed by the doctor. */}
            Okay
          </p>
        </div>
      </Box>
    </Modal>
  );
};

export default SuccessModal;
