import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSychart} from '../Contexts/SychartContext';
import './subheader.css';

const SyChartButtons = () => {
  const {data, goBack, showDoctor, startOver} = useSychart();
  const {t} = useTranslation();
  const lastQ = !!data?.last_questionnaire_id;
  const renderGoBack = () => {
    if (lastQ) {
      return (
        <div
          className="top-btn"
          id="goBack"
          onClick={goBack}
          onMouseEnter={e => {
            e.target.style.fontWeight = 'bold';
          }}
          onMouseLeave={e => {
            e.target.style.fontWeight = '500';
          }}>
          {t('go_back_button')}
        </div>
      );
    }
  };

  const onStartOver = () => {
    let confirmStart = window.confirm(t('start_over_alert_message'));
    if (confirmStart) {
      localStorage.removeItem('questionaryId');
      startOver();
    }
  };
  if (showDoctor) {
    return null;
  }
  return (
    <div className={`main-btn-div ${lastQ ? '' : 'one'}`}>
      {renderGoBack()}
      <div
        className="top-btn"
        id="startOver"
        onClick={onStartOver}
        onMouseEnter={e => {
          e.target.style.fontWeight = 'bold';
        }}
        onMouseLeave={e => {
          e.target.style.fontWeight = '500';
        }}>
        {t('start_over')}
      </div>
    </div>
  );
};

export default SyChartButtons;
