import React from 'react';
import './progressBars.css';
import {isHtmlDirRtl} from '../../redux/Redcuers/LocaleDirSlice';
import {useSelector} from 'react-redux';

const ProgressBars = props => {
  const {completed} = props;
  const isDirRtl = useSelector(isHtmlDirRtl);

  const effectiveCompletedProgress = () => {
    if (completed <= 4.2) {
      return 4.2;
    } else {
      return completed;
    }
  };

  const arEffectiveCompletedProgress = () => {
    if (completed <= 5) {
      return 5;
    } else {
      return completed;
    }
  };

  const fillerStyles = {
    width: `${effectiveCompletedProgress()}%`,
  };

  const fillerArabicStyles = {
    width: `${arEffectiveCompletedProgress()}%`,
  };

  return (
    <>
      <div className={isDirRtl ? 'arabic-process-bar' : 'process-bar'}>
        <div
          className={isDirRtl ? 'arabic-fill-processbar' : 'fill-processbar'}
          style={isDirRtl ? fillerArabicStyles : fillerStyles}>
          <span
            className={
              isDirRtl ? 'arabic-label-processbar' : 'label-processbar'
            }></span>
        </div>
      </div>
    </>
  );
};

export default ProgressBars;
