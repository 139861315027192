import BodyPartsSlice from './BodyPartsSlice';
import BodySectionsSlice, {BodySectionsThunks} from './BodySectionsSlice';

const SuperDoctorSlices = {
  BodyPartsSlice,
  BodySectionsSlice
};

export default SuperDoctorSlices;

export const SuperDoctorThunks = {
  ...BodySectionsThunks
};
