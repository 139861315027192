import * as React from "react"

function HintBulbIcon(props) {
  return (
    <svg
      width={33}
      height={34}
      viewBox="0 0 33 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.821 2.991a1.443 1.443 0 01-1.446-1.44c0-.794.648-1.439 1.446-1.439.798 0 1.446.645 1.446 1.44 0 .794-.648 1.44-1.446 1.44zm9.205 3.795a1.436 1.436 0 010-2.036 1.452 1.452 0 012.045 0 1.436 1.436 0 010 2.036 1.452 1.452 0 01-2.045 0zm3.813 9.16c0-.794.648-1.439 1.446-1.439.799 0 1.447.645 1.447 1.44 0 .794-.648 1.439-1.447 1.439a1.444 1.444 0 01-1.446-1.44zm-28.929 0c0-.794.648-1.439 1.447-1.439.798 0 1.446.645 1.446 1.44 0 .794-.648 1.439-1.446 1.439a1.444 1.444 0 01-1.447-1.44zM5.57 4.75a1.452 1.452 0 012.046 0 1.436 1.436 0 010 2.036 1.452 1.452 0 01-2.045 0 1.436 1.436 0 010-2.036zm6.912 27.03h8.678c.799 0 1.447.451 1.447 1.008 0 .557-.648 1.008-1.447 1.008h-8.678c-.799 0-1.447-.45-1.447-1.008 0-.557.648-1.007 1.447-1.007z"
        fill="#fff"
      />
      <path
        d="M7.71 15.945a9.04 9.04 0 003.905 7.443l.434.303v5.21c0 .238.194.431.434.431h8.678c.24 0 .434-.193.434-.432v-5.21l.434-.302a9.04 9.04 0 003.906-7.443c0-5.01-4.08-9.069-9.113-9.069-5.033 0-9.112 4.06-9.112 9.069z"
        stroke="#fff"
        strokeWidth={1.8514}
      />
    </svg>
  )
}

export default HintBulbIcon
