import * as React from 'react';

function TickIconSvg(props) {
  return (
    <svg
      width={13}
      height={14}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M5 10.5a5.006 5.006 0 01-5-5v-.1a5.001 5.001 0 115 5.1zM2.705 5.296L2 6l2 2 4-4-.704-.71L4 6.585l-1.295-1.29z"
        fill="url(#paint0_linear_1104_2774)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1104_2774"
          x1={-1.36111}
          y1={-0.319685}
          x2={0.647051}
          y2={15.8492}
          gradientUnits="userSpaceOnUse">
          <stop stopColor={props.color || '#7A30FE'} />
          <stop offset={1} stopColor={props.color || '#7A30FE'} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default TickIconSvg;
