const EndPoints = {
  ABHAAADHARUPDATEOTPGENERATE: '/abha/aadhaar_generate_otp',
  ABHAAADHARUPDATEVERIFY: '/abha/update',
  ABHAOTPGENERATE: '/abha/generate_otp',
  ABHAOTPVERIFY: '/abha',
  GETBODYPARTSECTION: '/body_part_positions',
  BODYSECTIONS: '/body_sections',
  BODYPARTPOSITION: '/body_part_positions',
  BODY_PART: id => `/body_parts${id ? '/' + id : ''}`,
  BODY_PART_SYMPTOMS: id => `/body_parts/${id}/symptom_list`,
  SYMPTOM_KEYWORDS: id => `/symptoms/${id}/symptom_keywords`,
  SYMPTOM_KEYWORDS_CREATE: id => `/keyword_collections${id ? '/' + id : ''}`,
  KEYWORDS_COLLECTION: '/keywords/',
  DEPARTMENT: '/departments',
  DOCTORSLOGIN: '/doctors/login',
  PATIENTLOGIN: '/patients/login',
  SUBSPECIALIST: '/subspecialties',
  SYMPTOMS: '/symptoms',
  INTRODUCTION: '/introduction_screens',
  SYMPTOMSQUESTION: '/symptom_questionnaires',
  SYMPTOMSQUIESTIONOPTION: '/symptom_options',
  LANGUAGES: '/languages',
  STARTQUESTIONARY: '/patient_responses',
  PATIENTUPDATE: '/patients',
  PROFILE: '/doctors',
  DASHBOARD: '/dashboard',
  DEPARTMENTS_LIST: '/departments/list',
  SUBSPECIALISTIES_LIST: '/subspecialties/list',
  PENDING_QUESTIONNAIRE: '/patient_responses/pending',
  FollowUp: '/follow_up_questions',
  FollowUpOptions: '/follow_up_options',
  HEADINGOPTIONS: '/heading_options',
  EXAMHEADINGS: '/exam_headings',
  EXAMENDITEMS: '/exam_end_items',
  PATIENTS_RESPONSES: '/patient_responses/patient_response_list',
  PRESIGNEDURL: '/presigned_urls',
  LOGOUTDOCTOR: '/doctors/logout',
  LOGOUTPATIENT: '/patients/logout',
  SYPMTOMCATEGORIES: '/categories',
  CONNECTDOCTOR: '/connect_doctor',
  LOGINWITHUSERNAMEANDPASSWORD: '/doctors/login_with_username',
  PATIENTRESPONSESEARCH: `/patient_responses`,
  DOCUMENTS: '/documents',
  FACTS: '/facts',
  REGISTRATION_LOGIN: '/patients/login_with_registration_code',
  DOCTORS_LIST: '/receptionist/doctors',
  REGISTERPATIENT: '/receptionist/patient',
  REGISTERED_PATIENT: '/receptionist/patients',
  PATIENT_UPDATE: '/receptionist/patient_update',
  SYMPTOM_SEARCH: id => `/patient_responses/${id}/symptom_search`,
  NURSE_PROFILE: '/nurse/nurse_profile',
  START_OVER: '/start_over',
  PREVIOUS: '/previous',
};

export default EndPoints;
