import React from 'react';
import './departmentList.css';
import {useTranslation} from 'react-i18next';
import {DepartmentHeader} from '../commonComponent';

function DepartmentList(props) {
  const {t} = useTranslation();
  return (
    <>
      <DepartmentHeader depart title={t('select_department')} />
      <div className="sychart-container-root-department">
        {props.data &&
          props.data.map((item, index) => {
            return (
              <div
                className="sychart-container-box-department"
                key={index}
                onClick={() => {
                  props.onClick(item.id);
                }}>
                <div className="display-img-department-div">
                  {item.image_url && (
                    <img
                      src={item.image_url}
                      className="department-image"
                      alt={`Department${index}-img`}
                    />
                  )}
                  <div className="department-title">{item.name}</div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default DepartmentList;
