import React from 'react';
import {
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import Colors from '../Resources/COLORS/Colors';
import CloseIcon from '@mui/icons-material/Close';

function CommonDropDown(props) {
  const {setData, showData, setData2, showData2, value, dropDownStyle} = props;

  const returnMapFunction = () => {
    return props.data.map(item => {
      let showval, setVal;

      if (setData2 && showData2) {
        setVal = item[setData] + ' ' + item[setData2];
        showval = item[showData] + ' ' + item[showData2];
      } else {
        setVal = item[setData];
        showval = item[showData];
      }

      return (
        <MenuItem key={item[setData]} value={setVal} style={{width: '100%'}}>
          {showval}
        </MenuItem>
      );
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        ...dropDownStyle,
      },
    },
  };

  const handleOnChange = event => {
    const {
      target: {value},
    } = event;
    props.onChange &&
      props.onChange(typeof value === 'string' ? value.split(',') : value);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: Colors.APPCIRCLE,
        light: Colors.APPCIRCLE,
        dark: Colors.APPCIRCLE,
      },
    },
  });

  const errrorText = props.error ? '#FF0000' : Colors.BLACK;

  const flexStyle = props.showClose && value ? {display: 'flex'} : {};

  const clearData = () => {
    if (props.showClose && value) {
      return (
        <CloseIcon
          style={{
            display: 'block',
            margin: 'auto',
            marginLeft: '-60px',
            zIndex: '1',
            padding: '5px',
          }}
          onClick={() => {
            props.onChange && props.onChange('');
          }}
        />
      );
    }
  };

  if (props.customize) {
    return (
      <ThemeProvider theme={theme}>
        <FormControl style={props.formControlStyle}>
          <div style={flexStyle}>
            <InputLabel id={props.labelId}>{props.label}</InputLabel>
            <Select
              error={props.error}
              helperText={props.helperText ?? ''}
              sx={{borderRadius: '15px', color: errrorText, ...props.sx}}
              labelId={props.labelId}
              value={value}
              onChange={handleOnChange}
              input={<OutlinedInput label={props.label} />}
              MenuProps={MenuProps}
              style={props.selectStyle}>
              {props.data && returnMapFunction()}
            </Select>

            {clearData()}
          </div>
          {props.error && props.helperText && (
            <div
              style={{
                color: 'red',
                display: 'block',
                textAlign: 'left',
                alignSelf: 'flex-start',
              }}>
              {props.helperText}
            </div>
          )}
        </FormControl>
      </ThemeProvider>
    );
  } else {
    return (
      <FormControl style={props.formControlStyle}>
        <div style={flexStyle}>
          <InputLabel id={props.labelId}>{props.label}</InputLabel>
          <Select
            error={props.error}
            helperText={props.helperText ?? ''}
            sx={{color: errrorText, ...props.sx}}
            labelId={props.labelId}
            value={value}
            onChange={handleOnChange}
            input={<OutlinedInput label={props.label} />}
            MenuProps={MenuProps}
            style={props.selectStyle}>
            {props.data && returnMapFunction()}
          </Select>

          {clearData()}
        </div>
        {props.error && props.helperText && (
          <div
            style={{
              color: 'red',
              display: 'block',
              textAlign: 'left',
              alignSelf: 'flex-start',
            }}>
            {props.helperText}
          </div>
        )}
      </FormControl>
    );
  }
}

export default CommonDropDown;
