import {combineReducers} from '@reduxjs/toolkit';
import SuperDoctorSlices from './SuperDoctor';
import UserDataSlice from './UserDataSlice';
import LanguageSlice from './LanguageSlice';
import LocaleDirSlice from './LocaleDirSlice';

const reducers = combineReducers({
  UserDataSlice,
  LanguageSlice,
  LocaleDirSlice,
  ...SuperDoctorSlices,
});

export default reducers;
