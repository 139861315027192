import React from 'react';
import Colors from '../Resources/COLORS/Colors';
import {useTranslation} from 'react-i18next';

function Facts(props) {
  const {onClick, data, isShowBtn, btn} = props;
  console.log(props);

  const {t} = useTranslation();

  const renderImage = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <img
          src={data.image_url}
          alt={data.fact}
          style={{
            margin: 'auto',
            borderRadius: '17px',
            maxWidth: '100vw',
            maxHeight: '250px',
            border: '5px solid white',
            boxShadow: '0px 4.7px 4px 0px #00000040',
          }}
        />
      </div>
    );
  };

  const renderFact = () => {
    return (
      <div
        style={{
          textAlign: 'center',
          margin: '20px',
          fontSize: '22px',
          color: '#7f4bf6',
          textDecoration: 'underline',
        }}>
        {data.fact}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div
        style={{
          textAlign: 'justify',
          margin: '20px',
          lineHeight: '33px',
          fontWeight: '600',
          fontSize: '18px',
        }}>
        {data.content}
      </div>
    );
  };

  const renderHeaderButtons = () => {
    return isShowBtn && btn();
  };

  const renderSubmitBtn = () => {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div
          onClick={() => onClick()}
          style={{
            marginTop: '15px',
            maxWidth: '100%',
            background: Colors.APPCIRCLE,
            fontSize: '18px',
            textAlign: 'center',
            color: Colors.WHITE,
            fontWeight: '700',
            padding: '12px 25px',
            borderRadius: '50px',
            '&:hover': {
              background: Colors.APPCIRCLE,
            },
          }}>
          {t('ok_got_it')}
        </div>
      </div>
    );
  };

  return (
    <>
      {renderHeaderButtons()}
      {data.image_url && renderImage()}
      {renderFact()}
      {renderContent()}
      {renderSubmitBtn()}
      <div style={{marginBottom: '30px'}} />
    </>
  );
}

export default Facts;
