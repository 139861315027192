import * as React from 'react';

function BackArrowSvg(props) {
  return (
    <svg
      width={30}
      height={27}
      viewBox="0 0 30 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.24 26.4a1.997 1.997 0 01-.831-.178 1.915 1.915 0 01-.67-.504L.438 14.662A1.795 1.795 0 010 13.492c0-.427.155-.84.438-1.17l9.628-11.056A1.956 1.956 0 0111.37.608a1.987 1.987 0 011.41.418c.393.313.64.762.687 1.25.047.487-.11.972-.437 1.348l-8.607 9.877 8.318 9.877a1.772 1.772 0 01.26 1.97 1.873 1.873 0 01-.72.773 1.99 1.99 0 01-1.041.279z"
        fill="#fff"
      />
      <path
        d="M2.15 13.5h25.8"
        stroke="#fff"
        strokeWidth={3.225}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default BackArrowSvg;
