import * as React from 'react';

function CrossIcon(props) {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M14.532 26.643c6.661 0 12.11-5.45 12.11-12.11 0-6.661-5.449-12.111-12.11-12.111-6.66 0-12.11 5.45-12.11 12.11 0 6.661 5.45 12.111 12.11 12.111z"
        fill="#E2E2E2"
      />
      <path
        d="M11.105 17.96l6.855-6.855M17.96 17.96l-6.855-6.855"
        stroke={props.primaryColor || '#7a30fe'}
        strokeWidth={1.81659}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CrossIcon;
