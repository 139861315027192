import {useCallback} from 'react';
import {useNavigate} from 'react-router';
import ApiClient from '../networking/ApiManager';
import {useSelector} from 'react-redux';
import Utility from '../commonComponents/Utility';

function useAppNavigation() {
  const navigate = useNavigate();
  const acceptedTnc = useSelector(state => {
    const user = state.UserDataSlice.userData;
    return !Utility.isPatient || user.accepted_terms_and_conditions;
  });

  const goToFullSyChart = useCallback(
    (id, patient, replace = false) => {
      const path = `/patient/${patient.id}/patient_responses/${id}`;
      navigate(path, {state: patient, replace: replace});
    },
    [navigate],
  );

  const navigateToSychart = useCallback(
    (pId, id) => {
      if (!acceptedTnc) {
        navigate('/terms_and_conditions');
      } else if (pId && id) {
        navigate('/patient/' + pId + '/sychart/' + id);
      } else if (id) {
        navigate('/sychart/' + id);
      }
    },
    [navigate, acceptedTnc],
  );

  const startSychart = useCallback(
    params => {
      const {id = '', patient_id = ''} = params || {};
      if (id) {
        navigateToSychart(patient_id, id);
      } else {
        ApiClient.createQuistonary({
          ...Utility.sychartParms(),
          ...params,
          include_body_part: true,
        })
          .then(res => {
            navigateToSychart(patient_id, res.data.data.id);
            Utility.removeSychartParams();
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    [navigateToSychart],
  );

  const startPendingSychart = useCallback(
    params => {
      ApiClient.pendingPatientResponses(params)
        .then(res => {
          navigate('/sychart/' + res.data.data.id);
        })
        .catch(err => {
          console.log(err);
        });
    },
    [navigate],
  );

  return {
    goToFullSyChart,
    startSychart,
    startPendingSychart,
  };
}

export default useAppNavigation;
