import * as React from 'react';

function SychartQuestionArrow(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        opacity={1}
        d="M8.653 1v8.96a5.913 5.913 0 00-1.63-1.89L.473 3.128C-.433 2.444.05 1 1.186 1h7.467z"
        fill={props.platformPrimaryColor}
      />
      <path
        d="M8.75 0v8.96a5.932 5.932 0 00-1.648-1.89L.478 2.128C-.438 1.444.051 0 1.198 0H8.75z"
        fill="#fff"
      />
      <path
        opacity={0.1}
        d="M8.75 0v8.96a5.932 5.932 0 00-1.648-1.89L.478 2.128C-.438 1.444.051 0 1.198 0H8.75z"
        fill={props.platformPrimaryColorForOpacity}
      />
    </svg>
  );
}

export default SychartQuestionArrow;
