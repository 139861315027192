import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + '/api/web/p1',
});

api.interceptors.request.use(
  config => {
    let token = localStorage.getItem('token');
    if (!token) {
      token = new URL(window.location.href).searchParams.get('access_token');
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

const apis = {
  getMessages: () => api.get('/chat_messages'),
  sendMessage: data => api.post('/chat_messages', data),
};

export default apis;
