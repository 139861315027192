import Colors from '../Resources/COLORS/Colors';

const absolute = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

export default {
  bodyImageContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dashLine: (image, left) => ({
    position: 'absolute',
    width: '0.01px',
    height: image.height,
    border: '1px dashed #000',
    borderLeft: 'none',
    [left ? 'left' : 'right']: image.width * 0.2,
  }),
  appBar: {
    backgroundColor: Colors.APPCIRCLE,
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftBtn: {
    width: '10%',
    backgroundColor: Colors.APPCIRCLE,
    '&:hover': {
      backgroundColor: Colors.APPCIRCLE,
    },
  },
  absolute,
  bodyParts: {
    ...absolute,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'space-around',
    justifyContent: 'space-between',
  },
  fulWH: {
    width: '100%',
    height: '100%',
  },
  centerDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  relativeWH: imageLayout => ({
    position: 'relative',
    width: imageLayout.width,
    height: imageLayout.height,
  }),
};
