const Colors = {
  PURPLE: '#650BF9',
  WHITE: 'white',
  BLACK: 'black',
  LIGHTBLACK: '#b8b8b8',
  BORDER: '#00000040',
  APPPURPLE: '#E000FF',
  BLACKBORDER: '#00000040',
  APPCIRCLE: '#7A03FE',
  SHADOWCOLOR: '#00000025',
  MODALBG: '#00000040',
  BLUE_PART: 'rgba(52, 96, 208, 1)',
  PURPLE_GRADIENT: `linear-gradient(to right, rgba(122, 3, 254, 1), rgba(152, 58, 255, 1)) 1`,
  GREY: '#CDCDCD',
};

export default Colors;
