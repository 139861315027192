import React from 'react';
import './bodypartlistshow.css';
import SubHeader from '../commonComponents/SubHeader';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import {SingleBodyShow} from '../bodyparts';
import PropTypes from 'prop-types';
import {useSychart} from '../Contexts/SychartContext';
import Colors from '../Resources/COLORS/Colors';
import SearchIcon from '@mui/icons-material/Search';
import {useTranslation} from 'react-i18next';

function BodyPartListShow(props) {
  const data = Array.isArray(props.data.data) ? props.data.data : [];
  const [searchActive, setSearchActive] = React.useState(false);
  const {t} = useTranslation();
  const {submitResponse, headerWidth} = useSychart();

  const renderIndicator = (onClickHandler, isSelected, index, label) => {
    const item = data[index];
    return (
      <div
        role="button"
        aria-label={`Select ${label} as current slide`}
        aria-pressed={isSelected ? 'true' : 'false'}
        title={`Select ${label} as current slide`}
        onClick={onClickHandler}
        className="pointer"
        style={styles.indicatorDiv(isSelected)}>
        <img
          src={item.image_url}
          style={styles.indicatorImage}
          alt="indicator"
        />
        <div style={styles.indicaorSelected(isSelected)} />
      </div>
    );
  };

  const renderSections = (item, i) => (
    <SingleBodyShow key={item.id} index={i} data={item} />
  );

  const completeBodyPart = () => {
    submitResponse(undefined, undefined, undefined, undefined, {
      complete_body_part: true,
      record_id: null,
    });
  };

  return (
    <React.Fragment>
      {!searchActive && (
        <SubHeader {...props} title={t('select_body_section')} />
      )}
      {!searchActive && (
        <div style={styles.parentDiv}>
          <div style={styles.childDiv(headerWidth)}>
            <div
              variant="contained"
              onClick={completeBodyPart}
              style={styles.completeBodyPart}>
              {t('no_more_symptoms')}
            </div>
            <div
              onClick={() => setSearchActive(true)}
              style={styles.searchIcon}>
              <p style={styles.search}>{t('search')}</p>
              <SearchIcon />
            </div>
          </div>
        </div>
      )}
      {searchActive ? (
        <div style={styles.parentDiv}></div>
      ) : (
        <Carousel
          swipeable
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={10}
          renderIndicator={renderIndicator}
          showArrows={false}
          showStatus={false}
          showThumbs={false}>
          {data.map(renderSections)}
        </Carousel>
      )}
    </React.Fragment>
  );
}

export default BodyPartListShow;

BodyPartListShow.propTypes = {
  data: PropTypes.object,
  isActive: PropTypes.bool,
};

const styles = {
  indicatorDiv: selected => ({
    width: '45px',
    height: '55px',
    margin: '0 7px',
    display: 'inline-block', // added style for horizontal display
    verticalAlign: 'middle', // added style for horizontal display
    borderRadius: '50%',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: selected ? 'rgb(122, 3, 254)' : 'transparent',
    backgroundColor: 'white',
    position: 'relative',
  }),
  indicatorImage: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  indicaorSelected: selected => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: selected ? 'rgba(199, 179, 226, 0.4)' : 'transparent',
    borderRadius: '50%',
  }),
  parentDiv: {
    marginTop: '15px',
    marginBottom: '15px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  childDiv: width => ({
    width,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  search: {
    marginRight: '10px',
  },
  searchIcon: {
    background: Colors.APPCIRCLE,
    textAlign: 'center',
    color: Colors.WHITE,
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: '10px',
    padding: '5px 15px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
    '&:hover': {
      background: Colors.APPCIRCLE,
    },
  },
  searchContainer: {
    marginTop: '15px',
    marginBottom: '15px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  completeBodyPart: {
    background: Colors.APPCIRCLE,
    fontSize: '18px',
    textAlign: 'center',
    color: Colors.WHITE,
    fontWeight: '700',
    padding: '3px 15px',
    alignSelf: 'center',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',

    '&:hover': {
      background: Colors.APPCIRCLE,
    },
  },
};
