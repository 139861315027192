import React from 'react';
import {nextIconWhite} from '../Resources/Images';
import {useTranslation} from 'react-i18next';

function Intro(props) {
  const {
    title,
    desc,
    setStepCount,
    stepCount,
    completeIntro,
    total,
    style,
    btnTitle,
    text1,
    text2,
    styleDesc,
  } = props;
  const {t} = useTranslation();

  const introComplete = () => {
    if (stepCount || stepCount == 0) {
      if (stepCount == total) {
        completeIntro();
      } else {
        setStepCount(stepCount + 1);
      }
    } else {
      completeIntro();
    }
  };

  const returnIntro = () => {
    return (
      <div
        style={{
          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#00000060',
          zIndex: 1,
        }}>
        <div
          style={{
            background: 'white',
            padding: '20px',
            borderRadius: '15px',
            width: '280px',
            ...style,
          }}>
          {title && (
            <div
              style={{
                textDecoration: 'underline',
                fontWeight: '700',
                textAlign: 'center',
                fontSize: '18px',
                color: '#7A03FE',
              }}>
              {title}
            </div>
          )}

          <div style={{marginTop: '20px', ...styleDesc}}>
            {desc}
            {text1 && (
              <b style={{color: '#7A03FE', fontWeight: '700'}}> {text1} </b>
            )}
            {text2 && <span>{text2}</span>}
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div
              style={{
                background: '#7A03FE',
                alignSelf: 'center',
                color: 'white',
                borderRadius: '100px',
                padding: '6px 30px',
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                fontWeight: '700',
                zIndex: 999999999,
              }}
              onClick={introComplete}>
              {btnTitle || t('next')}
              {!btnTitle && (
                <img
                  src={nextIconWhite}
                  alt={'next-icon'}
                  style={{height: '9px', marginLeft: '5px'}}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return returnIntro();
}

export default Intro;
