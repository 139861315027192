import {useEffect} from 'react';

function usePreventBackButton() {
  useEffect(() => {
    window.addEventListener('popstate', () => {
      window.history.go(1);
    });
    return () => {
      window.removeEventListener('popstate', () => {
        window.history.go(1);
      });
    };
  }, []);
}

export default usePreventBackButton;
