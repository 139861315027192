import * as React from "react"

function PopupCloseBtnSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      {...props}
    >
      <path
        d="M14.963 26.738c6.66 0 12.11-5.45 12.11-12.111 0-6.66-5.45-12.11-12.11-12.11-6.661 0-12.11 5.45-12.11 12.11-.001 6.66 5.449 12.11 12.11 12.11z"
        fill="#E2E2E2"
      />
      <path
        d="M11.535 18.054l6.855-6.855M18.39 18.054l-6.855-6.855"
        stroke="#28232E"
        strokeWidth={1.81659}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PopupCloseBtnSvg