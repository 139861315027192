import React from 'react';

function SychartAnswerArrow(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        opacity={1}
        d="M.097 1v8.96a5.913 5.913 0 011.63-1.89l6.55-4.942C9.183 2.444 8.7 1 7.564 1H.097z"
        fill="#9992A5"
      />
      <path
        d="M0 0v8.96a5.932 5.932 0 011.648-1.89l6.624-4.942C9.188 1.444 8.699 0 7.552 0H0z"
        fill="#F1F1F1"
      />
    </svg>
  );
}

export default SychartAnswerArrow;
