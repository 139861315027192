import React from 'react';

function NextArrowIconSvg(props) {
  return (
    <svg
      width={17}
      height={18}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10.4 4.978l4.152 4.152-4.152 4.152M2.924 9.13h11.512"
        stroke={props.color || '#fff'}
        strokeWidth={1.36807}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default NextArrowIconSvg;
