import React, {useEffect} from 'react';
// eslint-disable-next-line no-unused-vars
import NurseRoutes from './NurseRoutes';
import {useDispatch} from 'react-redux';
import AsyncThunks from '../redux/AsyncThunks';
import Utility from '../commonComponents/Utility';
import {NoAuthSychart} from '../sychart';
import { setHtmlDir } from '../redux/Redcuers/LocaleDirSlice';
import { useTranslation } from 'react-i18next';

const requiredParams = [
  'login_mode',
  'hospital_code',
  'language_code',
  'department_code',
  'department_id',
  'access_token',
];

const Routes = () => {
  const dispatch = useDispatch();
  const syToken = new URL(window.location.href).searchParams.get('sy_token');
  const syLocale = new URL(window.location.href).searchParams.get('locale');
  const platformFont = new URL(window.location.href).searchParams.get('platform_font_family');
  const platformPrimaryColor = new URL(window.location.href).searchParams.get('platform_primary_color');
  const platformBackgroundColor = new URL(window.location.href).searchParams.get('platform_primary_background_color');
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(AsyncThunks.getLanguage(''));
    dispatch(setHtmlDir(t('text_direction')));
  }, [dispatch, t]);

  useEffect(() => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const localToken = localStorage.getItem('token');
    const params = requiredParams.reduce((acc, param) => {
      const value = url.searchParams.get(param);
      const ifToken =
        param === 'access_token' && Utility.isPatient && localToken;
      if (value || ifToken) {
        localStorage.setItem(param, value || ifToken);
        return {...acc, [param]: value || ifToken};
      } else {
        return acc;
      }
    }, {});
    Utility.handleAccessTokenInParams(params);
  }, []);


    return <NoAuthSychart platformPrimaryColor={platformPrimaryColor} platformFont={platformFont} platformBackgroundColor={platformBackgroundColor} syLocale={syLocale} token={syToken} />;
};

export default Routes;
