import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {bulbIcon} from '../../Resources/Images';
import AlertModal from './AlertModal';

function SychartHintBulb({paragraph, color, hint, style, bulbStyle}) {
  const {t} = useTranslation();
  const [hintModal, setHintModal] = useState(false);
  const parString = typeof paragraph === 'string' ? paragraph.trim() : '';
  const lastWord = parString.split(' ').pop();

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
        <p
          style={{
            // display: 'flex',
            // alignItems: 'center',
            textAlign: 'center',
            color,
            ...style,
          }}>
          {!!hint ? parString.slice(0, -lastWord.length) : parString}{' '}
          {/* Render the paragraph without the last word */}
          {!!hint && (
            <span style={{display: 'inline-flex', alignItems: 'center'}}>
              {lastWord} {/* Render the last word wrapped in a span */}
              <img
                src={bulbIcon}
                style={{
                  width: '30px',
                  height: '30px',
                  marginLeft: '5px',
                  ...bulbStyle,
                }}
                className="pointer"
                alt="icon"
                onClick={() => setHintModal(true)}
              />
            </span>
          )}
        </p>
      </div>
      <AlertModal
        header={t('hint')}
        text={t('want_hint')}
        visible={hintModal}
        close={() => setHintModal(false)}
      />
    </>
  );
}

export default SychartHintBulb;
