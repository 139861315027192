import * as React from 'react';

function CarousalNextButton(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={18}
      viewBox="0 0 17 18"
      fill="none"
      {...props}>
      <path
        d="M10.326 4.738l4.152 4.152-4.152 4.152M2.85 8.89h11.512"
        stroke={props.stopColor}
        strokeWidth={1.36807}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CarousalNextButton;
