import {Button, Modal} from '@mui/material';
import React from 'react';
import {crossIcon} from '../../Resources/Images';
import {AlertIcon, HintBulbIcon, PopupCloseBtnSvg} from '../resource/svg';
import Colors from '../../Resources/COLORS/Colors';
import './AlertModal.css';
import {useSychart} from '../../Contexts/SychartContext';
import {useTranslation} from 'react-i18next';

const AlertModal = ({
  visible,
  close,
  text,
  alert,
  header,
  hideCross,
  onConfirm,
  locale,
}) => {
  const {startOver} = useSychart();
  const {t} = useTranslation();

  const startOverHandler = () => {
    !!onConfirm ? onConfirm() : startOver();
    close();
  };

  return (
    <Modal
      open={visible}
      sx={{
        background: Colors.MODALBG,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="bacground-model-line">
        <div className="popup-icon-Background">
          {!!alert ? <AlertIcon /> : <HintBulbIcon />}
        </div>
        <div className="hint-bubble">
          {!hideCross && (
            <PopupCloseBtnSvg
              className={locale ? 'ar-close-popup' : 'close-popup'}
              src={crossIcon}
              onClick={close}
            />
          )}
          <div className="hint-text-box">
            <h4 className="hint-title">{header || t('do_you_know')}</h4>
            <div className="hint-description">{text || ''}</div>
          </div>
          {alert && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
              }}>
              <Button
                onClick={close}
                variant="contained"
                sx={{
                  backgroundColor: '#E2E2E2',
                  textTransform: 'none',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#E2E2E2',
                  },
                }}
                component="span">
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={startOverHandler}
                sx={{
                  backgroundColor: '#FF5353',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#FF5353',
                  },
                }}>
                {t('confirm')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AlertModal;
