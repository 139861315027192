import {
  firstCarousalImage,
  lastCarousalImage,
  psyscen,
  instructions,
  health,
} from './Images';

const Strings = {
  INSURANCE_DATA: [
    {
      'SL NO': 1,
      CATEGORY: 'INS',
      name: 'ADNIC-ABU DHABI NATIONAL INSURANCE COMPANY',
      insurance_plan: 'INS017',
    },
    {
      'SL NO': 2,
      CATEGORY: 'TPA',
      name: 'ALMADHALLAH HEALTH CARE MANAGEMENT FZ-LLC',
      insurance_plan: 'TPA003',
    },
    {
      'SL NO': 3,
      CATEGORY: 'INS',
      name: 'AMERICAN LIFE INSURANCE COMPANY-METLIFE',
      insurance_plan: 'INS013',
    },
    {
      'SL NO': 4,
      CATEGORY: 'TPA',
      name: 'ECARE',
      insurance_plan: 'TPA029',
    },
    {
      'SL NO': 5,
      CATEGORY: 'TPA',
      name: 'FMC NETWORK UAE MANAGEMENT CONSULTANCY',
      insurance_plan: 'TPA010',
    },
    {
      'SL NO': 6,
      CATEGORY: 'INS',
      name: 'OMAN',
      insurance_plan: 'INS012',
    },
    {
      'SL NO': 7,
      CATEGORY: 'TPA',
      name: 'MEDNET UAE',
      insurance_plan: 'TPA036',
    },
    {
      'SL NO': 8,
      CATEGORY: 'TPA',
      name: 'NAS Administration Services LLC',
      insurance_plan: 'TPA004',
    },
    {
      'SL NO': 9,
      CATEGORY: 'INS',
      name: 'NATIONAL GENERAL INSURANCE -NGI',
      insurance_plan: 'INS038',
    },
    {
      'SL NO': 10,
      CATEGORY: 'TPA',
      name: 'NEURON L.L.C',
      insurance_plan: 'TPA001',
    },
    {
      'SL NO': 11,
      CATEGORY: 'TPA',
      name: 'INAYAH TPA LLC',
      insurance_plan: 'TPA008',
      Column7: ' ',
    },
    {
      'SL NO': 12,
      CATEGORY: 'TPA',
      name: 'NEXTCARE ',
      insurance_plan: 'TPA002',
    },
    {
      'SL NO': 13,
      CATEGORY: 'TPA',
      name: 'WHEALTH INTERNATIONAL L.L.LC',
      insurance_plan: 'TPA032',
    },
    {
      'SL NO': 14,
      CATEGORY: 'TPA',
      name: 'VIDAL MEDICAL SERVICES L.L.C',
      insurance_plan: 'TPA021',
    },
    {
      'SL NO': 15,
      CATEGORY: 'TPA',
      name: 'AMITHY HEALTH',
      insurance_plan: 'TPA006',
    },
    {
      'SL NO': 16,
      CATEGORY: 'TPA',
      name: 'IRIS HEALTH SERVICES L.L.C',
      insurance_plan: 'TPA030',
    },
    {
      'SL NO': 17,
      CATEGORY: 'TPA',
      name: 'MAXCARE MIDDILE EAST',
      insurance_plan: 'TPA015',
    },
    {
      'SL NO': 18,
      CATEGORY: 'TPA',
      name: 'PENTA CARE',
      insurance_plan: 'TPA013',
    },
    {
      'SL NO': 19,
      CATEGORY: 'TPA',
      name: 'FOURTUNE HEALTHCARE L.L.C',
      insurance_plan: 'TPA025',
    },
    {
      'SL NO': 20,
      CATEGORY: 'INS',
      name: 'DUBAI INSURANCE COMPANY',
      insurance_plan: 'INS005',
    },
    {
      'SL NO': 21,
      CATEGORY: 'TPA',
      name: 'ENET TPA L. L.C',
      insurance_plan: 'TPA038',
    },
    {
      'SL NO': 22,
      CATEGORY: 'Other',
      name: 'Other',
      insurance_plan: 'Other',
    },
  ],
  CAROUSAL_DATA: [
    {
      image: firstCarousalImage,
      title:
        'Welcome to the Joy of Living Retreat ! This is a Self Care SyChart.',
      description:
        'A custom survey designed by Dr Sonia Gupte with a world class clinical and integrative medicine team,in order to monitor your wellness holistically over time.',
    },
    {
      image: firstCarousalImage,
      title:
        'Welcome to the Joy of Living Retreat ! This is a Self Care SyChart.',
      description:
        "This powerful survey protocol is administered at certain time durations in order to truly derive how you are doing - from the outside, and inside. The survey marries Dr Sonia's complete prowess in integrative medicine along with her extensive clinical insight.",
    },
    {
      image: firstCarousalImage,
      title:
        'Welcome to the Joy of Living Retreat ! This is a Self Care SyChart.',
      description:
        'This helps Dr Sonia and her team track your progress and offer personalized support, thereby helping you heal faster from within.',
    },
    {
      image: lastCarousalImage,
      title:
        'Thank you for taking time out to fill this.\njoin us in this journey to self actualization and Joyful Living!',
      description: '',
    },
  ],
  NOVA_DATA: [
    {
      image: psyscen,
      title: 'Welcome to the PsyScan!',
      description: [
        'Our PsyScan is a great way to better understand your mental health. Curated and crafted by skilled medical professionals from renowned medical institutions across the country, it is an amalgamation of clinical experience and clinical research',
        'This combination of the best of both worlds allows us to help you identify subtle and early indicators of burnout, depression, anxiety and a host of other issues.',
      ],
    },
    {
      image: instructions,
      title: 'Instructions',
      description: [
        'Select Yes, and click Next to start the module',
        'Select the options you believe are accurate for you, and then select next to proceed to the next question.',
        'All the data is anonymised, and we place data privacy at the top of our priorities. So we urge you to be as honest as possible when answering these questions.',
      ],
    },
    {
      image: health,
      title: `To patients, from doctors, with love ❤️`,
      titleIcon: true,
      description: [
        'Apart from the current status of your mental health, we will also be providing you with customised recommendations curated by a crack team of physician-scientists and mental health researchers.',
        'Remember, you’re not alone on this journey towards better mental health. We are here to support you every step of the way. So let’s begin!',
      ],
    },
  ],
  NoInternetData: {
    title:
      'no_internet_connection_was_found_check_your_connection_again_or_try_again',
    whoops: 'whoops',
  },
};

export default Strings;
