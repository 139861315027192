import {Box, Modal} from '@mui/material';
import React, {useEffect} from 'react';
import {Carousel} from 'react-responsive-carousel';
import CarousalPreviousButton from '../Resources/Images/Websvgs/CarousalPreviousButton';
import CarousalNextButton from '../Resources/Images/Websvgs/CarousalNextButton';
import './EnsoniaModal.css';
import ApiClient from '../networking/ApiManager';
import Colors from '../Resources/COLORS/Colors';
import {useTranslation} from 'react-i18next';
import IntroductionModelBackgroundAssets from '../newSychart/resource/svg/IntroductionModelBackgroundAssets';

const IntroductionModal = ({isOpen, setIsOpen, id, platformPrimaryColor}) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [introductionData, setIntroductionData] = React.useState([]);
  const [logoImage, setLogoImage] = React.useState('');
  const crousalRef = React.useRef(null);
  const {t} = useTranslation();

  useEffect(() => {
    ApiClient.getIntroductionData(id)
      .then(res => {
        const resData = res.data.data;
        setLogoImage(resData[0].hospital_logo_url);
        setIntroductionData(resData);
      })
      .catch(err => {
        console.log('err', err);
      });
  }, []);

  useEffect(() => {
    setActiveIndex(0);
  }, [isOpen]);

  const renderSections = (item, i) => {
    const smallFont = item.description.length < 515 ? 16 : 14;
    const smallModelDesign = item.description.length < 301;
    const maxHeightDes = smallModelDesign ? 195 : 300;
    const hideDes = item.description.length === 0;

    return (
      <>
        <div key={i} className="section">
          <div className="image-container">
            <img src={item.image_url} className="models-image" alt="carousal" />
          </div>
          <h1
            style={{
              marginTop: 20,
              marginBottom: 15,
            }}
            className="title">
            {item.title}
          </h1>
          <div className="model-description">
            <div
              style={{
                fontSize: smallFont,
                maxHeight: !!hideDes ? 0 : maxHeightDes,
                height: !!hideDes ? 0 : null,
              }}
              className={`model-description-content ${
                i !== introductionData.length - 1
                  ? 'previousModel'
                  : 'lastModel'
              }`}
              dangerouslySetInnerHTML={{__html: item.description}}
            />
          </div>
        </div>
        {i === introductionData.length - 1 && (
          <button
            className="model-close-button"
            onClick={() => {
              setIsOpen(false);
            }}>
            {t('begin_sy_chart')}
          </button>
        )}
      </>
    );
  };

  const backgroundStyle = () => {
    return (
      <div className="background-model">
        <img
          src={logoImage}
          alt="novaBenefitsLogo"
          className="nova-logo-modal"
        />
        <IntroductionModelBackgroundAssets
          className="modalRightBackgroundElement"
          platformPrimaryColor={platformPrimaryColor}
        />
        <IntroductionModelBackgroundAssets
          className="modalLeftBackgroundElement"
          platformPrimaryColor={platformPrimaryColor}
        />
      </div>
    );
  };

  const renderIdicator = () => {
    const isPrevButtonDisabled = activeIndex === 0;
    const isNextButtonDisabled = activeIndex === introductionData.length - 1;
    const prevButtonStroke = isPrevButtonDisabled
      ? Colors.GREY
      : platformPrimaryColor || Colors.APPCIRCLE;
    const nextButtonStopColor = isNextButtonDisabled
      ? Colors.GREY
      : Colors.WHITE;

    return (
      <div className="model-buttons">
        <div className="model-indicator">
          <div className="model-back-button">
            <div
              onClick={() => {
                crousalRef.current.decrement();
              }}
              className={
                isPrevButtonDisabled
                  ? 'model-previous-button-icon-container-disabled'
                  : 'model-previous-button-icon-container'
              }>
              <CarousalPreviousButton
                className="model-previous-button-icon"
                stroke={prevButtonStroke}
                disabled={isPrevButtonDisabled}
              />
              <div
                className="model-previous-button-title"
                style={{
                  color: prevButtonStroke,
                }}>
                {t('back')}
              </div>
            </div>
          </div>
          <div className="modelIndex">
            {activeIndex + 1 + '/' + introductionData.length}
          </div>
          <div className="model-next-button">
            <div
              onClick={() => {
                crousalRef.current.increment();
              }}
              className={
                isNextButtonDisabled
                  ? 'none model-button-icon-container-disabled'
                  : 'model-button-icon-container'
              }>
              <div
                className="model-next-button-title"
                style={{
                  color: nextButtonStopColor,
                }}>
                {t('next')}
              </div>
              <CarousalNextButton
                className="model-next-button-icon"
                stopColor={nextButtonStopColor}
                disabled={isNextButtonDisabled}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!introductionData.length) return null;

  return (
    <Modal className="model" open={isOpen}>
      <Box className="nova-model">
        {backgroundStyle()}
        <Carousel
          ref={crousalRef}
          swipeable={false}
          showStatus={false}
          showThumbs={false}
          onChange={setActiveIndex}
          showIndicators={false}
          renderArrowNext={() => null}>
          {introductionData.map(renderSections)}
        </Carousel>
        {renderIdicator()}
      </Box>
    </Modal>
  );
};
export default IntroductionModal;
