import React from 'react';
import './SychartButtonFooter.css';
import {NextArrowIconSvg} from '../resource/svg';
import {useTranslation} from 'react-i18next';
import {useSychart} from '../../Contexts/SychartContext';
import {CommonTextInput} from '../../commonComponents';
import {imageAddIcon} from '../../Resources/Images';
import {useSelector} from 'react-redux';
import {isHtmlDirRtl} from '../../redux/Redcuers/LocaleDirSlice';

function SychartButtonFooter(props) {
  const {data, goBack} = useSychart();
  const {t} = useTranslation();
  const lastQuestId = !!data?.last_questionnaire_id;
  const isDirRtl = useSelector(isHtmlDirRtl);

  const getUserInput = style => {
    return (
      <div
        style={{
          ...style,
        }}>
        <CommonTextInput
          className="inputImage"
          multiple
          type="file"
          iconStyle={{height: '62px', width: '62px'}}
          showFileIcon
          icon={imageAddIcon}
          platformPrimaryColor={props.platformPrimaryColor}
          onChange={props.setMediaData}
          accept="image/*"
        />
      </div>
    );
  };

  if (!lastQuestId && props.hideNext) {
    return null;
  }

  return (
    <div className="sychart-chat-footer">
      {lastQuestId && (
        <button
          style={{
            color: props.platformPrimaryColor,
            borderColor: props.platformPrimaryColor,
            borderWidth: '1px',
          }}
          onClick={goBack}
          type="button"
          className="previous-question-button">
          <NextArrowIconSvg
            color={props.platformPrimaryColor}
            style={{transform: isDirRtl ? 'none' : 'scaleX(-1)'}}
          />
          {t('back')}
        </button>
      )}
      {props.askForImage && getUserInput()}
      {!props.hideNext && props.button()}
    </div>
  );
}

export default SychartButtonFooter;
