import React from 'react';
import './sychartOptions.css';
import {t} from 'i18next';

const SychartOptions = ({
  optionRender,
  key,
  setSelectedIds,
  setSelectedId,
  setMultipleLabel,
  setShowBackgroundShadow,
  noneStyle,
  noneHoverStyles,
  symptom,
  slider,
  lastQuestId,
  hideNext,
}) => {
  let optionClass = slider
    ? 'slider-question-option'
    : !lastQuestId && hideNext
    ? 'lastQ-question-option'
    : 'question-option';

  return (
    <div className={optionClass} key={key}>
      {optionRender()}
      {!!symptom && (
        <div
          onClick={() => {
            setSelectedIds(new Set());
            setSelectedId(0);
            setMultipleLabel('');
            setShowBackgroundShadow(true);
          }}
          className="symptoms-option-button none-symptom-option"
          style={{
            ...noneStyle,
          }}
          hoverStyle={{...noneHoverStyles}}>
          {t('none')}{' '}
        </div>
      )}
    </div>
  );
};

export default SychartOptions;
