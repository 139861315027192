import React from 'react';
import './departmentHeader.css';
import {useTranslation} from 'react-i18next';
import {BackArrowSvg} from '../resource/svg';
import AlertModal from './AlertModal';
import {isHtmlDirRtl} from '../../redux/Redcuers/LocaleDirSlice';
import {useSelector} from 'react-redux';

const DepartmentHeader = props => {
  const [showModal, setShowModal] = React.useState(false);
  const {t} = useTranslation();
  const isDirRtl = useSelector(isHtmlDirRtl);

  const exitSychart = () => {
    console.warn('exitSychart');
    const params = {event: 'exit_sychart'};
    window.parent.postMessage(params, '*');
  };

  return (
    <div
      className="departheader-container"
      style={
        {
          // marginTop: Utility.isPatientPrams ? '-24px' : '-14px',
        }
      }>
      <div className="departheader">
        <div className="departheader-btn-div">
          <div className="departheader-title-div">
            {!!props.depart && (
              <BackArrowSvg
                style={{transform: isDirRtl ? 'scaleX(-1)' : 'none'}}
                className="departheader-backIcon"
                onClick={() => setShowModal(true)}
              />
            )}
            <h2 className="departheader-title">{props.title}</h2>
            <div></div>
          </div>
        </div>
      </div>
      <AlertModal
        hideCross
        alert
        visible={showModal}
        close={() => setShowModal(false)}
        text={t('are_you_sure_you_want_to_exit_sychart')}
        header={t('exit_sy_chart')}
        onConfirm={exitSychart}
      />
    </div>
  );
};

export default DepartmentHeader;
