import * as React from 'react';

function AlertIcon(props) {
  return (
    <svg
      width={33}
      height={34}
      viewBox="0 0 47 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_1879_235)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8 33.206H13.012c-6.176 0-8.757-4.376-5.767-9.723l5.554-9.917 5.233-9.318c3.169-5.664 8.367-5.664 11.535 0L40.355 23.5c2.99 5.347.391 9.724-5.768 9.724H23.8v-.018zm0-23.223a1.33 1.33 0 011.336 1.323v8.824a1.33 1.33 0 01-1.335 1.323 1.33 1.33 0 01-1.335-1.323v-8.824A1.33 1.33 0 0123.8 9.983zm-.01 13.676c-.983 0-1.78.79-1.78 1.764 0 .975.797 1.765 1.78 1.765h.016c.983 0 1.78-.79 1.78-1.765 0-.974-.796-1.764-1.78-1.764h-.016z"
          fill="#fff"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1879_235"
          x={0.908365}
          y={0}
          width={45.78}
          height={43.4068}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={5.09164} />
          <feGaussianBlur stdDeviation={2.54582} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.956863 0 0 0 0 0.780392 0 0 0 0 0.384314 0 0 0 0.4 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1879_235"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1879_235"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default AlertIcon;
