import * as React from 'react';

function CarousalPreviousButton(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={18}
      viewBox="0 0 17 18"
      fill="none"
      {...props}>
      <path
        d="M7.052 4.737L2.9 8.89l4.152 4.152"
        stroke="url(#paint0_linear_7449_3541)"
        strokeWidth={1.36807}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.528 8.889H3.016"
        stroke="url(#paint1_linear_7449_3541)"
        strokeWidth={1.36807}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7449_3541"
          x1={7.6169}
          y1={4.05722}
          x2={4.42785}
          y2={16.8974}
          gradientUnits="userSpaceOnUse">
          <stop stopColor={props.stroke} />
          <stop offset={1} stopColor={props.stroke} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7449_3541"
          x1={16.0953}
          y1={8.80678}
          x2={16.0776}
          y2={10.4482}
          gradientUnits="userSpaceOnUse">
          <stop stopColor={props.stroke} />
          <stop offset={1} stopColor={props.stroke} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CarousalPreviousButton;
