import * as React from 'react';

function CameraIcon(props) {
  return (
    <svg
      width={62}
      height={62}
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g filter="url(#filter0_d_4739_1718)">
        <circle cx={31} cy={29} r={22} fill="#fff" />
        <circle
          cx={31}
          cy={29}
          r={21.725}
          stroke="#E2E2E2"
          strokeWidth={0.55}
        />
      </g>
      <path
        d="M20 19.1v-3.3h2.2v3.3h3.3v2.2h-3.3v3.3H20v-3.3h-3.3v-2.2H20zm3.3 6.6v-3.3h3.3v-3.3h7.7l2.013 2.2H39.8c1.21 0 2.2.99 2.2 2.2v13.2c0 1.21-.99 2.2-2.2 2.2H22.2c-1.21 0-2.2-.99-2.2-2.2v-11h3.3zm7.7 9.9c3.036 0 5.5-2.464 5.5-5.5s-2.464-5.5-5.5-5.5a5.502 5.502 0 00-5.5 5.5c0 3.036 2.464 5.5 5.5 5.5zm-3.52-5.5A3.516 3.516 0 0031 33.62a3.516 3.516 0 003.52-3.52A3.516 3.516 0 0031 26.58a3.516 3.516 0 00-3.52 3.52z"
        fill={props.platformPrimaryColor || '#7a30fe'}
      />
      <defs>
        <filter
          id="filter0_d_4739_1718"
          x={0.2}
          y={0.4}
          width={61.6}
          height={61.6}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2.2} />
          <feGaussianBlur stdDeviation={4.4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.196078 0 0 0 0 0.168627 0 0 0 0 0.243137 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4739_1718"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_4739_1718"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default CameraIcon;
