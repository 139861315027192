import React, {useEffect} from 'react';
import Alert from '@mui/material/Alert';

function LocalAlert(props) {
  useEffect(() => {
    const timeOut = props.time || 2000;
    const timer = setTimeout(() => {
      props.onClose && props.onClose();
    }, timeOut);
    return () => clearTimeout(timer);
  }, [props.alertText]);

  return (
    <div
      style={{
        padding: '0 20px',
        position: 'fixed',
        top: 20,
        right: 20,
        zIndex: 9999999,
      }}>
      <Alert severity={props.severity} style={{justifyContent: 'center'}}>
        {props.alertText}
      </Alert>
    </div>
  );
}

export default LocalAlert;
