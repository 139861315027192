import React, {useState, useEffect, useRef} from 'react';
import './CustomDropdown.css';
import {DownGreyArrow, DropDownUpArrow} from '../resource/svg';
import Utility from '../../commonComponents/Utility';

const CustomDropdown = ({
  durationTextKey,
  item,
  setSelectedId,
  platformPrimaryColor,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const dropdownRef = useRef(null);

  let durationData = [];

  for (let i = 1; i <= 30; i++) {
    durationData.push({
      key: i,
    });
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('reset', () => {
      if (selectedValue !== null) {
        setSelectedValue(null);
      }
    });
    return () => {
      document.removeEventListener('reset', () => {
        setSelectedValue(null);
      });
    };
  }, [selectedValue]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    const event = new CustomEvent('reset');
    document.dispatchEvent(event);
  };

  const selectOption = option => {
    setSelectedValue(option);
    setIsOpen(false);
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: '50px',
          padding: '5px 10px',
          border: '1px solid #ccc',
          borderColor:
            isOpen || !!selectedValue ? platformPrimaryColor : '#ccc',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        className="dropdown-header"
        onClick={toggleDropdown}>
        {!!selectedValue ? (
          <p style={{color: platformPrimaryColor}}>
            {`${selectedValue.key} ${Utility.removeLastChar(
              selectedValue.key,
              item.option_code,
            )}`}
          </p>
        ) : (
          <p style={{color: !!isOpen ? platformPrimaryColor : 'grey'}}>
            {' '}
            {item.option_label}
          </p>
        )}
        <div style={{marginLeft: 5, alignItems: 'center', display: 'flex'}}>
          {!isOpen ? (
            <DownGreyArrow
              height={20}
              width={20}
              color={
                !!selectedValue || !!isOpen ? platformPrimaryColor : 'grey'
              }
            />
          ) : (
            <DropDownUpArrow
              height={20}
              width={20}
              color={
                !!selectedValue || !!isOpen ? platformPrimaryColor : 'grey'
              }
            />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="dropdowns">
          {/* <div class="triangle"></div> */}
          <ul className="dropdown-options">
            {durationData.map(option => (
              <li
                key={option.key}
                onClick={() => {
                  selectOption(option);
                  durationTextKey(
                    option.key,
                    `${option.key} ${Utility.removeLastChar(
                      option.key,
                      item.option_code,
                    )}`,
                  );
                  setSelectedId(item.id);
                }}
                className="dropdown-option">
                {`${option.key} ${Utility.removeLastChar(
                  option.key,
                  item.option_code,
                )}`}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
