import ApiClient from '../networking/ApiManager';
import Actions from '../redux/Actions';
import store from '../redux/Store';
import {Strings} from '../Resources';

const REQUIRED_PARAMS = ['department_id', 'hospital_code', 'department_code'];

class Utility {
  static isNurse = localStorage.getItem('role') === 'nurse';
  static isUae = window.location.hostname.split('.')[0] === 'uae';
  static rootPath = '/select_language';
  static isStaging = window.location.hostname.split('.')[0] === 'staging';
  static isAdmin = localStorage.getItem('user') === 'admin';
  static isUhid = localStorage.getItem('loginMode') === 'uhid';
  static isSyChartPage = window.location.pathname.includes('/sychart/');
  static isPatient = localStorage.getItem('user') === 'patient';
  static isAuthSychart = new URL(window.location.href).searchParams.get(
    'sy_token',
  );
  static isPatientPrams =
    new URL(window.location.href).searchParams.get('role') === '';

  static sychartParms = () =>
    REQUIRED_PARAMS.reduce((acc, curr) => {
      const urlString = window.location.href;
      const url = new URL(urlString);
      const item = localStorage.getItem(curr) || url.searchParams.get(curr);
      if (item) {
        return {...acc, [curr]: item};
      } else {
        return acc;
      }
    }, {});

  static removeSychartParams = () => {
    REQUIRED_PARAMS.forEach(i => localStorage.removeItem(i));
  };

  static isEnsoniaDepartment = departmentCode => {
    return departmentCode === 'ENSONIA';
  };

  static isNovaDepartment = departmentCode => {
    return departmentCode === 'NOVA';
  };

  static getImageMeta(image, cb) {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      cb(img);
    };
  }

  static firstUpperCase(str) {
    if (typeof str !== 'string') {
      return str;
    } else {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }

  static removeLastChar(value, str) {
    if (value === 1) {
      return str.slice(0, -1);
    } else {
      return str;
    }
  }

  static getBaseUrl(endpoint) {
    const validReferrers = ['mydocsy.com', 'docsy.ae'];
    let BASE_URL;

    if (validReferrers.some(referrer => document.referrer.includes(referrer))) {
      BASE_URL = document.referrer + endpoint;
    } else {
      BASE_URL = process.env.REACT_APP_BASE_URL + `/${endpoint}`;
      console.error('Base url is REACT_APP_BASE_URL');
    }

    return BASE_URL;
  }

  static formatDate(forFormat) {
    const date = new Date(forFormat);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: 'numeric',
      hour12: true,
    });
  }

  static getInsurancePlanName(insaurance) {
    return (
      Strings.INSURANCE_DATA.find(
        item => item.insurance_plan === insaurance.insurance_plan,
      )?.name || ''
    );
  }

  static normlise(str) {
    if (typeof str !== 'string') {
      return str;
    } else {
      return str
        .replace(/([A-Z])/g, ' $1')
        .replace(/_/g, ' ')
        .toLowerCase()
        .trim();
    }
  }

  static successAlert(message) {
    store.dispatch(Actions.setAlertMessages({success: message}));
  }

  static errorAlert(message) {
    store.dispatch(Actions.setAlertMessages({error: message}));
  }

  static addQSId(item, key) {
    const id = key ? item[key] : item.id;
    return '[QS-' + id + '] ';
  }

  static addOPId(item, key) {
    const id = key ? item[key] : item.id;
    return '[OP-' + id + '] ';
  }

  static addSYId(item, key) {
    const id = key ? item[key] : item.id;
    return '[SY-' + id + '] ';
  }

  static localeLang(locale) {
    const body = document.querySelector('body');
    if (locale === 'ar') {
      body.style.fontFamily = "'Amiri', sans-serif";
    } else {
      body.style.fontFamily = "'Cairo', sans-serif";
    }
  }

  static rightDir(isRight) {
    if (!!isRight) {
      document.documentElement.setAttribute('dir', 'rtl');
    } else {
      document.documentElement.setAttribute('dir', 'ltr');
    }
  }

  static scrollToBottom() {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  }
  static handleStartSychart = async (
    patient,
    params = Utility.sychartParms(),
  ) => {
    if (params.department_code) {
      window.history.replaceState({}, document.title, '/');
      if (patient.accepted_terms_and_conditions) {
        const res = await ApiClient.createQuistonary(params);
        Utility.removeSychartParams();
        const id = res.data.data.id;
        window.location.pathname = '/sychart/' + id;
      } else {
        window.location.pathname = '/terms_and_conditions';
      }
    }
  };

  static handleAccessTokenInParams = ({access_token = '', ...rest}) => {
    const token = localStorage.getItem('token');
    if (access_token) {
      localStorage.setItem('languageId', 1);
      localStorage.setItem('user', 'patient');
      localStorage.setItem('token', access_token);
      ApiClient.getPatient(undefined, access_token)
        .then(res => {
          Utility.handleStartSychart(res.data.data, rest);
        })
        .catch(err => {
          console.log(err);
        });
    } else if (Object.keys(rest).length > 0 && !token) {
      window.history.replaceState({}, document.title, '/');
      window.location.pathname = '/';
    }
  };
}

export default Utility;
