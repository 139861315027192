import PropTypes from 'prop-types';
import React from 'react';
import './listComponent.css';

const ListComponent = props => {
  const data = Array.isArray(props.data) ? props.data : [];
  const emptyTitle = props.emptyTitle || 'No data found';
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(
      () => {
        setLoading(props.isFetching);
      },
      loading ? 500 : 0,
    );
    return () => clearTimeout(timer);
  }, [props.isFetching, loading]);

  const listHeader = () => {
    if (props.listHeader) {
      return props.listHeader();
    }
  };

  const listEmptyView = () => {
    if (props.ListEmptyView) {
      return props.ListEmptyView();
    } else {
      return (
        <div className="spinner">
          {loading ? (
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <h3
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}>
              {emptyTitle}
            </h3>
          )}
        </div>
      );
    }
  };

  const renderList = () => {
    if (data.length > 0) {
      if (props.renderItem) {
        return data.map(props.renderItem);
      }
    } else {
      return listEmptyView();
    }
  };

  const listFooterComponent = () => {
    if (props.ListFooterComponent) {
      return props.ListFooterComponent();
    }
  };

  const content = () => (
    <>
      {listHeader()}
      {renderList()}
      {listFooterComponent()}
    </>
  );

  if (props.freeStyle) {
    return content();
  } else {
    return (
      <div
        style={
          !props.removeStyleProp ? {...styles.container, ...props.style} : {}
        }
        className={props.className}>
        {content()}
      </div>
    );
  }
};

export default ListComponent;

ListComponent.propTypes = {
  data: PropTypes.array,
  listHeader: PropTypes.func,
  ListEmptyView: PropTypes.func,
  renderItem: PropTypes.func,
  style: PropTypes.object,
  emptyTitle: PropTypes.string,
  ListFooterComponent: PropTypes.func,
  className: PropTypes.string,
  removeStyleProp: PropTypes.bool,
  freeStyle: PropTypes.bool,
  isFetching: PropTypes.bool,
};

const styles = {
  container: {
    overflow: 'scroll',
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
  },
  emptyView: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
