import React from 'react';
import './sychartSingleQuestion.css';
import {useSelector} from 'react-redux';
import {isHtmlDirRtl} from '../../redux/Redcuers/LocaleDirSlice';
import SychartAnswerArrow from '../resource/svg/SychartAnswerArrow';

const SychartSingleAnswer = props => {
  const isDirRtl = useSelector(isHtmlDirRtl);
  const sychartAnswer = () => {
    if (
      props?.data?.view_type === 'duration_question' ||
      props?.data?.data?.question?.question_type == 'duration_select'
    ) {
      return props?.durationText;
    } else if (
      props?.data?.data?.question?.question_type === 'multiple_select'
    ) {
      const optionsList = props?.data?.data?.options;
      const optionLabels = [...props.selectedIds]
        .map(id => {
          const optionData = optionsList.find(item => item.id === id);
          return optionData ? optionData.option_label : '';
        })
        .join(', ');
      return optionLabels;
    } else if (
      props?.data?.data?.question?.question_type === 'text' ||
      props?.data?.data?.question?.question_type === 'numeric' ||
      (props.showInput && props.text)
    ) {
      return props?.text;
    } else {
      return props?.answer;
    }
  };

  const renderImages = () => {
    const moreImagesCount =
      props.images.length > 4 ? `+${props.images.length - 4}` : '';
    return (
      <div
        style={{
          display: 'flex',
          position: 'relative',
          flexWrap: 'wrap',
        }}>
        {props.images.slice(0, 4).map((image, index) => {
          return (
            <div
              key={index}
              style={{
                width: '70px',
                height: '90.5px',
                margin: 5,
              }}>
              <img
                src={URL.createObjectURL(image)}
                alt="img"
                style={{
                  width: 70,
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </div>
          );
        })}
        {props.images.length > 4 && (
          <div
            style={{
              position: 'absolute',
              bottom: 5,
              right: 5,
              width: '70px',
              height: '90.5px',
              backgroundColor: 'rgba(0,0,0,0.7)',
            }}>
            <p
              style={{
                color: 'white',
                margin: '35%',
                fontSize: 20,
              }}>
              {moreImagesCount}
            </p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={isDirRtl ? 'ar-patient-chat' : 'patient-chat'}>
      <SychartAnswerArrow
        width="20"
        height="21"
        className={
          isDirRtl
            ? 'ar-answer-sychart-message-arrow'
            : 'answer-sychart-message-arrow'
        }
      />
      <div
        className={`${
          isDirRtl
            ? 'ar-patient-reply-question-background-shadow ar-patient-reply-answer-arrow'
            : 'patient-reply-question-background-shadow patient-reply-answer-arrow'
        }`}>
        <div
          className={`chat-container ${
            isDirRtl
              ? 'ar-patient-reply-answer-arrow ar-patient-reply-box'
              : 'patient-reply-answer-arrow patient-reply-box '
          }`}>
          <p className="patient-reply-question">
            {!props.hide && (props?.multipleLabels || sychartAnswer())}
            {props?.images && renderImages()}
          </p>
        </div>
      </div>
    </div>
  );
};
export default SychartSingleAnswer;
