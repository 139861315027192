import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  userData: {},
  alertMessages: {
    success: '',
    error: '',
  },
};

export const counterSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setAlertMessages: (state, action) => {
      state.alertMessages = action.payload;
    },
    updateSychartCount: state => {
      state.userData.sy_balance -= 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const UserDataActions = counterSlice.actions;

export default counterSlice.reducer;
