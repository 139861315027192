import React from 'react';
import {useNavigate} from 'react-router';
import {usePreventBackButton} from '../hooks';
import {error404Image} from '../Resources/Images';
import './NotFound.css';

const NotFound = () => {
  const navigate = useNavigate();
  const btnClick = e => {
    e.preventDefault();
    navigate('/', {replace: true});
  };
  usePreventBackButton();
  return (
    <div className="Error404">
      <img className="Error404Image" src={error404Image} alt="404-err-img" />
      <p className="Errortext">
        We can't seem to find a page you're looking for.
      </p>
      <button type="submit" onClick={btnClick} className="Back-to-homeButoon">
        Back to homepage
      </button>
    </div>
  );
};

export default NotFound;
