import {createSlice} from '@reduxjs/toolkit';

const LocaleDirSlice = createSlice({
  name: 'htmlDir',
  initialState: {
    htmlDir: '',
  },
  reducers: {
    setHtmlDir: (state, action) => {
      state.htmlDir = action.payload;
    },
  },
});

export const {setHtmlDir} = LocaleDirSlice.actions;

export const isHtmlDirRtl = state => {
  return state.LocaleDirSlice.htmlDir === 'rtl';
};

export default LocaleDirSlice.reducer;
