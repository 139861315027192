import * as React from "react"

function ExitPage(props) {
    return (
        <svg
            width={15}
            height={12}
            viewBox="0 0 11 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M5.932 0C4.815 0 3.904.895 3.904 1.998v2.155h2.747c.197 0 .353.154.353.347a.35.35 0 01-.353.346H3.904v2.151C3.904 8.1 4.815 9 5.942 9h2.23c1.121 0 2.032-.896 2.032-1.998v-5C10.204.897 9.288 0 8.167 0H5.932zM2.761 2.948a.344.344 0 00-.49-.005L.957 4.253a.344.344 0 000 .49l1.314 1.31a.349.349 0 00.243.103.348.348 0 00.248-.594l-.721-.715h1.863v-.694H2.041l.72-.715a.348.348 0 000-.49z"
                fill="#fff"
            />
        </svg>
    )
}

export default ExitPage
