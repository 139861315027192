import * as React from 'react';
function BackSmallArrowSvg(props) {
  return (
    <svg
      width={9}
      height={17}
      viewBox="0 0 9 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M7.445 16.613c-.183.001-.363-.037-.528-.113a1.217 1.217 0 01-.426-.32L.585 9.16a1.14 1.14 0 01-.278-.744c0-.27.098-.533.278-.743L6.699.653A1.262 1.262 0 018.423.5a1.138 1.138 0 01.159 1.65L3.116 8.421 8.4 14.694a1.125 1.125 0 01.165 1.252 1.19 1.19 0 01-.458.49c-.198.119-.428.18-.661.177z"
        fill="#fff"
      />
    </svg>
  );
}

export default BackSmallArrowSvg;
