import React, {useEffect, useState} from 'react';
import {Route, BrowserRouter as Router} from 'react-router-dom';

import BaseRoutes from '../routes/BaseRoutes';
import StartSychart from '../newSychart/components/StartSychart';
import {useTranslation} from 'react-i18next';
import Utility from '../commonComponents/Utility';
import {useSelector} from 'react-redux';
import {isHtmlDirRtl} from '../redux/Redcuers/LocaleDirSlice';
import ChatRoute from '../routes/chat/ChatRoute';

const BASE_URL = Utility.getBaseUrl('api/auth/sycharts');

const NoAuthSychart = ({
  token,
  syLocale,
  platformPrimaryColor,
  platformFont,
  platformBackgroundColor,
}) => {
  const {i18n} = useTranslation();
  const isDirRtl = useSelector(isHtmlDirRtl);
  const [platformPrimaryColorForOpacity, setPlatformPrimaryColorForOpacity] =
    useState('#ffff');

  useEffect(() => {
    Utility.localeLang(syLocale);
    i18n.changeLanguage(syLocale);
    Utility.rightDir(isDirRtl);
  }, [syLocale, isDirRtl, i18n]);

  useEffect(() => {
    if (platformPrimaryColor) {
      document.documentElement.style.setProperty(
        '--platform-primary-color',
        platformPrimaryColor,
      );
    }
    if (platformFont) {
      document.documentElement.style.setProperty(
        '--platform-font-family',
        platformFont,
      );
    }
    if (platformBackgroundColor) {
      document.documentElement.style.setProperty(
        '--platform-primary-background',
        platformBackgroundColor,
      );
    }

    // Check if the color is a gradient
    function isGradient(color) {
      return color && color.includes('gradient');
    }

    const colorForOpacity = isGradient(platformBackgroundColor)
      ? platformPrimaryColor
      : platformBackgroundColor;

    setPlatformPrimaryColorForOpacity(colorForOpacity);

    // Function to convert hex color to RGB
    function hexToRgb(hex) {
      if (!hex) return [0, 0, 0]; // Return black if hex is invalid
      hex = hex.replace(/^#/, '');
      let bigint = parseInt(hex, 16);
      let r = (bigint >> 16) & 255;
      let g = (bigint >> 8) & 255;
      let b = bigint & 255;
      return [r, g, b];
    }

    // Function to generate RGBA color with specified opacity
    function rgba(color, opacity) {
      const [r, g, b] = hexToRgb(color);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }

    if (colorForOpacity) {
      // Generate multiple opacity levels
      const opacities = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9];
      opacities.forEach(opacity => {
        document.documentElement.style.setProperty(
          `--primary-color-opacity-${opacity * 10}`,
          rgba(colorForOpacity, opacity),
        );
      });
    }
  }, [platformPrimaryColor, platformFont, platformBackgroundColor]);

  return (
    <Router>
      <BaseRoutes>
        <Route
          path="/"
          exact
          element={
            <StartSychart
              platformPrimaryColor={platformPrimaryColor}
              platformBackgroundColor={platformBackgroundColor}
              platformFont={platformFont}
              platformPrimaryColorForOpacity={platformPrimaryColorForOpacity}
              syid={token}
              baseUrl={BASE_URL}
            />
          }
        />
        <Route
          path="/chatbot"
          exact
          element={<ChatRoute syLocale={syLocale} />}
        />
      </BaseRoutes>
    </Router>
  );
};

export default NoAuthSychart;
