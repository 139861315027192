import * as React from 'react';

function LinkIconSvg(props) {
  return (
    <svg
      width={15}
      height={14}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M11.122 8.003v3.645a1.215 1.215 0 01-1.215 1.214h-7.29a1.215 1.215 0 01-1.215-1.214v-7.29a1.215 1.215 0 011.215-1.215h5.467"
        stroke="#7A30FE"
        strokeWidth={1.21495}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.303 1.376a.53.53 0 00-.53-.53H9a.53.53 0 000 1.06h4.241v4.241a.53.53 0 001.06 0V1.376zM7.079 8.819l7.068-7.068-.75-.75L6.33 8.07l.75.75z"
        fill="#7A30FE"
      />
    </svg>
  );
}

export default LinkIconSvg;
