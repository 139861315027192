import * as React from 'react';

function DropDownUpArrow(props) {
  return (
    <svg
      width={12}
      height={13}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M6.5 12.246a5.417 5.417 0 100-10.833 5.417 5.417 0 000 10.833z"
        stroke={props.color || '#7A30FE'}
        strokeWidth={0.928571}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.588 7.724L6.5 5.817l1.912 1.907"
        stroke={props.color || '#7A30FE'}
        strokeWidth={0.928571}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DropDownUpArrow;
