import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Utility from '../commonComponents/Utility';
import {SingleBodyPart, styles} from '.';

const SingleBodyShow = ({data, index}) => {
  const [bodyParts, setBodyParts] = useState([]);
  const [imageLayout, setImageLayout] = useState({
    width: 0,
    height: 0,
    reducedBy: 0,
  });

  const handleBoxDimensions = useCallback(() => {
    if (data.body_part_positions.length > 0 && imageLayout.width > 0) {
      const totalBoxes = data.body_part_positions.length;
      // equel size of total boxes number of those boxes should be equal to the totalBoxes
      const maxWidth = imageLayout.width * 0.45;
      let boxWidth = maxWidth;
      const boxHeight = boxWidth * 0.7;
      const boxX = imageLayout.width / totalBoxes;
      const boxY = imageLayout.height / totalBoxes;
      const sortedByY = data.body_part_positions.sort(
        (a, b) => a.position_y - b.position_y,
      );

      setBodyParts(
        sortedByY
          .map(item => {
            return {
              ...item,
              boxHeight,
              boxWidth,
              boxX,
              boxY,
            };
          })
          .filter(item => item?.symptom_signs?.length > 0),
      );
    }
  }, [data.body_part_positions, imageLayout]);

  const handleImageDimensions = useCallback(() => {
    Utility.getImageMeta(data.image_url, img => {
      // get device width then set image width to 90% of device width
      const deviceWidth = window.innerWidth;
      // if device width is greater then 500 then set image width to 500
      let width90 = deviceWidth * 0.9;
      if (deviceWidth > 500) {
        width90 = 500;
      }
      // compare how much percentage the image width has been reduced 0r increased
      const percentage = (width90 * 100) / img.width;
      // get ratio of image height and set image height according to ratio
      const imgHeight = (img.height * percentage) / 100;
      setImageLayout({
        width: width90,
        height: imgHeight,
        reducedBy: percentage,
      });
    });
  }, [data.image_url]);

  useEffect(() => {
    handleImageDimensions();
  }, [handleImageDimensions]);

  useEffect(() => {
    handleBoxDimensions();
  }, [handleBoxDimensions]);

  const renderParts = useCallback(
    (item, partIndex) => {
      return (
        <SingleBodyPart
          imageIndex={index}
          imageLayout={imageLayout}
          item={item}
          partIndex={partIndex}
          key={item.body_part_id}
          partId={data.id}
        />
      );
    },
    [imageLayout, index, data.id],
  );

  return (
    <div style={styles.centerDiv}>
      <div style={styles.relativeWH(imageLayout)}>
        <img src={data?.image_url} style={styles.fulWH} alt="part" />
        <div id="single-body-div" style={styles.bodyParts}>
          {bodyParts.map(renderParts)}
        </div>
      </div>
    </div>
  );
};

export default SingleBodyShow;

SingleBodyShow.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
};
