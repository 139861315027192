import React from 'react';
import {Route, Routes} from 'react-router-dom';
import NotFound from './NotFound';

const BaseRoutes = ({children}) => {
  return (
    <Routes>
      {children}
      <Route path="/download_link" element={<div />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default BaseRoutes;
