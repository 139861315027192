import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {CardActionArea} from '@mui/material';
import {CommonButton} from './commonComponents';
import './offline.css';
import {Strings} from './Resources';
import {useTranslation} from 'react-i18next';

const Offline = ({noInternetImg}) => {
  const {t} = useTranslation();
  return (
    <div className="offlinePage">
      <Card className="offline-card">
        <CardActionArea>
          <div>
            <img
              src={noInternetImg}
              alt="No Internet"
              style={{maxWidth: '95%'}}
            />
          </div>
          <CardContent>
            <Typography
              className="whoops-text"
              gutterBottom
              variant="h5"
              component="div">
              {`${t(Strings.NoInternetData.whoops)}!`}
            </Typography>
            <Typography className="text" variant="body2" color="text.secondary">
              {t(Strings.NoInternetData.title)}
            </Typography>
            <div className="button-view">
              <CommonButton
                onClick={() => window.location.reload()}
                style={{
                  width: '100px',
                  borderRadius: '50px',
                  display: 'flex',
                  textAlign: 'center',
                  marginTop: '15px',
                  justifyContent: 'center',
                }}
                label={t('refresh')}
              />
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default Offline;
