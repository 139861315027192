import * as React from "react"

function Restart(props) {
  return (
    <svg
      width={19}
      height={18}
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.864 8.412A3.419 3.419 0 015.32 9.805l-1.057.43a4.56 4.56 0 10.571-4.609V4.233h-1.14v3.04l.57.569h2.66v-1.14H5.482a3.42 3.42 0 016.381 1.71z"
        fill="#fff"
      />
    </svg>
  )
}

export default Restart
