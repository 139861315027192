import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import ApiClient from '../../networking/ApiManager';
import Utility from '../../commonComponents/Utility';
import Actions from '../../redux/Actions';
import AsyncThunks from '../../redux/AsyncThunks';
import DepartmentList from './DepartmentList';
import {Facts} from '../../patient';
import BodyPartListShow from '../../patient/BodyPartListShow';
import {SingleBodyShow} from '../../bodyparts';

import {
  ArabicIntrodModal,
  IntroductionModal,
  Loader,
} from '../../commonComponents';
import {usePreventBackButton} from '../../hooks';
import {SychartProvider} from '../../Contexts/SychartContext';
import SuccessModal from '../../patient/SuccessModal';
import SingleSychartQuestion from './SingleSychartQuestion';
import SychartSymtomsList from './SychartSymtomsList';
import {isHtmlDirRtl} from '../../redux/Redcuers/LocaleDirSlice';
import Offline from '../../Offline';
import {offlineError} from '../../Resources/Images';
// import OfflineImage from '../../OfflineImage';

const StartSychart = ({
  syid,
  baseUrl,
  platformPrimaryColor,
  platformFont,
  platformBackgroundColor,
  platformPrimaryColorForOpacity,
}) => {
  const {id = syid, patient_id} = useParams();
  const isDirRtl = useSelector(isHtmlDirRtl);

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const [storeImg, setStoreImg] = useState('');

  useEffect(() => {
    const convertImageToBase64 = imagePath => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const image = new Image();

      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        const base64Image = canvas.toDataURL('image/png');

        try {
          localStorage.setItem('isOnlineImage', base64Image);
        } catch (error) {
          console.log('Error storing image in local storage:', error);
        }
      };

      image.onerror = error => {
        console.error('Error loading image:', error);
      };

      image.src = imagePath;
    };

    convertImageToBase64(offlineError);
  }, []);

  useEffect(() => {
    const storedImage = localStorage.getItem('isOnlineImage');
    setStoreImg(storedImage);
  }, []);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const currentPatientResponseID = localStorage.getItem('questionaryId');
  const [sucsessModal, setSucsessModal] = useState(false);
  const [questionPerct, setQuestionPerct] = useState(0);
  const [showDoctor, setShowDoctor] = useState(false);
  const [isGoBack, setIsGoBack] = useState(false);
  const userData = useSelector(state => state.UserDataSlice.userData);
  const sychartParams = useMemo(() => {
    return {patient_id, id};
  }, [patient_id, id]);
  const dispatch = useDispatch();
  const dataUpdate = useCallback((newData, percentage = 0) => {
    if (baseUrl) {
      newData.id = syid;
    }
    setdata(newData);
    setQuestionPerct(percentage);
  }, []);

  const btnDisabled = useRef(false);
  const [data, setdata] = useState({});
  const [loading, setLoading] = useState(false);
  const questionId = useRef('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [comment, setComment] = useState('');
  const [introductionModal, setIntroductionModal] = useState(false);
  const isSymtoms = data.view_for === 'Symptom';

  const navigate = useNavigate();
  //set id to session storage
  const headerWidth = useMemo(() => {
    const deviceWidth = window.innerWidth;
    if (deviceWidth > 500) {
      return 500 * 0.9;
    } else {
      return deviceWidth * 0.9;
    }
  }, []);

  const checkForDoctor = useCallback(
    data => {
      if (!data) {
        return;
      }
      if (!userData?.external_identifier || baseUrl) {
        dataUpdate(data, data.question_percentage);
        setLoading(false);
        return;
      }
      ApiClient.getResposeDetails(data.id)
        .then(res => {
          const doctor = res.data.data.doctor_id;
          if (!doctor) {
            setShowDoctor(true);
          }
          dataUpdate(data, res.data.data.question_percentage);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dataUpdate, userData.external_identifier, baseUrl],
  );

  useEffect(() => {
    setLoading(true);
    ApiClient.pendingPatientResponses(sychartParams, baseUrl)
      .then(res => {
        const resData = res.data.data;
        checkForDoctor(resData);
        if (patient_id) {
          dispatch(
            Actions.setPatientStatus({
              id: patient_id,
              pending_response_id: resData?.id,
              status: 'pending',
            }),
          );
        }
      })
      .catch(err => {
        console.log('err', err);
      });
  }, [sychartParams]);

  useEffect(() => {
    return () => {
      if (Utility.isNurse && patient_id) {
        dispatch(AsyncThunks.getPatientsResponses(patient_id));
      }
    };
  }, [dispatch, patient_id]);

  useEffect(() => {
    return () => {
      setdata({});
    };
  }, []);

  useEffect(() => {
    if (data) {
      window.scrollTo(0, 0);
      data && localStorage.setItem('questionaryId', data.id);
      data && localStorage.setItem('previousId', data.id);
    }
  }, [data]);

  useEffect(() => {
    if (data?.view_type === 'body_part_list') {
      questionId.current = data.data.question.id;
    }
    if (data?.view_type === 'body_section_list') {
      questionId.current = data?.data[0]?.id;
    }
  }, [data]);

  useEffect(() => {
    if (data.last_questionnaire_id === null && !isGoBack) {
      setIntroductionModal(true);
    }
  }, [data.last_questionnaire_id]);

  console.warn('iss', Utility.isPatientPrams);

  const goBack = () => {
    setLoading(true);
    ApiClient.goBack(data?.id, sychartParams, baseUrl)
      .then(res => {
        const resData = res.data.data;
        setIsGoBack(true);
        dataUpdate(resData, resData.question_percentage);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const submitResponse = (
    record_id,
    question_id = questionId.current,
    duration = null,
    files = null,
    answer_index,
    args = {},
  ) => {
    if (btnDisabled.current) {
      return;
    }
    btnDisabled.current = true;
    const recordId = (record_id = record_id == 0 ? null : record_id);
    const params = {
      view_for: data?.view_for,
      subspecialty_id: data?.subspecialty_id,
      question_id: question_id,
      include_body_part: true,
      answer_index: answer_index,
      ...args,
    };

    if (record_id || record_id == 0) {
      params.record_id = recordId;
    }

    if (duration) {
      params.duration = duration;
    }
    if (comment) {
      params.comment = comment;
    }
    if (files) {
      params.documents = files;
    }
    submitForUpdate(params);
  };

  const submitForUpdate = params => {
    setLoading(true);
    ApiClient.updateQuestionnary(
      {
        ...params,
        ...sychartParams,
      },
      data?.id || currentPatientResponseID,
      baseUrl,
    )
      .then(res => {
        questionId.current = null;
        const resData = res.data.data;
        if (resData.status == 'completed') {
          if ((Utility.isNurse || Utility.isUhid) && !baseUrl) {
            setSucsessModal(true);
          } else {
            if (baseUrl) {
              window.parent.postMessage(`sychart_completed`, '*');
            } else {
              navigate(`/sychart_response/${resData?.id}`, {
                state: {comelete: true},
                replace: true,
              });
            }
          }
          setShowSuccess(true);
        } else {
          dataUpdate(resData, resData.question_percentage);
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
      })
      .finally(() => {
        btnDisabled.current = false;
      });
  };

  const renderQuestionList = () => {
    return (
      <DepartmentList isShowBtn data={data?.data} onClick={submitResponse} />
    );
  };

  const renderSymptomList = () => {
    return (
      <SychartSymtomsList
        isShowBtn
        data={data}
        onClick={submitResponse}
        id={data?.id}
        platformPrimaryColor={platformPrimaryColor}
        platformBackgroundColor={platformBackgroundColor}
        platformFont={platformFont}
        platformPrimaryColorForOpacity={platformPrimaryColorForOpacity}
      />
    );
  };

  const renderSingleQuestion = () => {
    return (
      <SingleSychartQuestion
        data={data?.data}
        lastQuestId={data?.last_questionnaire_id}
        hideNext={isSymtoms && !data.multiple_option_select}
        showSuccess={showSuccess}
        onClick={submitResponse}
        id={data?.id}
        isShowBtn
        isDurationQuestion={
          data?.view_type == 'duration_question' ||
          data.data?.question?.question_type === 'duration_select'
        }
        showDate={comment}
        setDate={setComment}
        baseUrl={baseUrl}
        syId={id}
        isSymtoms={isSymtoms}
        platformPrimaryColor={platformPrimaryColor}
        platformBackgroundColor={platformBackgroundColor}
        platformFont={platformFont}
        platformPrimaryColorForOpacity={platformPrimaryColorForOpacity}
      />
    );
  };

  const factsList = () => {
    return (
      <Facts
        data={data?.data}
        showSuccess={showSuccess}
        onClick={submitResponse}
        id={data?.id}
        isShowBtn
        isDurationQuestion={data?.view_type == 'duration_question'}
        showDate={comment}
        setDate={setComment}
      />
    );
  };

  const bodyPartListShow = () => {
    return (
      <BodyPartListShow
        data={data}
        isShowBtn
        onClick={submitResponse}
        id={data?.id}
      />
    );
  };

  const singleBodyPartShow = () => {
    return <SingleBodyShow data={data} onClick={submitResponse} isShowBtn />;
  };

  const renderQuestionView = useMemo(() => {
    if (loading) {
      return <Loader />;
    } else if (data?.view_type == 'list') {
      return renderQuestionList();
    } else if (data?.view_type == 'symptom_list') {
      return renderSymptomList();
    } else if (data?.view_type == 'fact') {
      return factsList();
    } else if (data?.view_type == 'body_section_list') {
      return bodyPartListShow();
    } else if (data?.view_type == 'body_part_list') {
      return singleBodyPartShow();
    } else {
      return renderSingleQuestion();
    }
  }, [data, comment]);

  const startOver = () => {
    setLoading(true);
    ApiClient.startOverSyChart(
      data?.id,
      {
        start_over: true,
        ...sychartParams,
      },
      baseUrl,
    )
      .then(res => {
        const resData = res.data.data;
        dataUpdate(resData, resData.question_percentage);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const values = {
    setLoading,
    questionPerct,
    submitResponse,
    data: data,
    submitForUpdate,
    goBack,
    startOver,
    headerWidth,
    setShowDoctor,
    showDoctor,
  };

  usePreventBackButton();
  return (
    <SychartProvider value={values}>
      {!isOnline ? (
        <Offline noInternetImg={storeImg} />
      ) : (
        <>
          {data && !showDoctor && renderQuestionView}
          <Loader loading={loading} />
          <SuccessModal
            visible={sucsessModal}
            patient_id={patient_id}
            res={currentPatientResponseID}
          />
          {isDirRtl ? (
            <ArabicIntrodModal
              id={id}
              platformPrimaryColor={platformPrimaryColor}
              platformBackgroundColor={platformBackgroundColor}
              platformPrimaryColorForOpacity={platformPrimaryColorForOpacity}
              isOpen={introductionModal}
              setIsOpen={setIntroductionModal}
            />
          ) : (
            <IntroductionModal
              platformPrimaryColor={platformPrimaryColor}
              platformBackgroundColor={platformBackgroundColor}
              platformPrimaryColorForOpacity={platformPrimaryColorForOpacity}
              id={id}
              isOpen={introductionModal}
              setIsOpen={setIntroductionModal}
            />
          )}
        </>
      )}
    </SychartProvider>
  );
};
export default StartSychart;
