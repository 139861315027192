import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Modal} from '@mui/material';
import {CommonTextInput, ListComponent} from '../commonComponents';
import {purpleCrossIcon} from '../Resources/Images';

const SearchBoxModal = (props, ref) => {
  const [isVisiable, setIsVisiable] = useState(false);
  const [searchText, setSearchText] = useState('');
  const data = useMemo(() => props.data || [], [props.data]);

  const filteredList = useMemo(() => {
    if (searchText.length > 0) {
      return data
        .filter(item => {
          const filterBy = props.filterKeys || ['name'];
          return filterBy.some(key => {
            const lowKey = item[key]?.toLowerCase() || '';
            const lowSearch = searchText.toLowerCase();
            return lowKey.includes(lowSearch);
          });
        })
        .sort((a, b) => {
          const filterBy = props.filterKeys || ['name'];
          const aKey = filterBy.find(key => {
            const lowKey = a[key]?.toLowerCase() || '';
            const lowSearch = searchText.toLowerCase();
            return lowKey.includes(lowSearch);
          });
          const bKey = filterBy.find(key => {
            const lowKey = b[key]?.toLowerCase() || '';
            const lowSearch = searchText.toLowerCase();
            return lowKey.includes(lowSearch);
          });
          const aKeyIndex = a[aKey]
            ?.toLowerCase()
            .indexOf(searchText.toLowerCase());
          const bKeyIndex = b[bKey]
            ?.toLowerCase()
            .indexOf(searchText.toLowerCase());
          if (aKeyIndex === 0 && bKeyIndex === 0) {
            return a[aKey]?.toLowerCase() > b[bKey]?.toLowerCase() ? 1 : -1;
          }
          return aKeyIndex > bKeyIndex ? 1 : -1;
        });
    } else {
      return data;
    }
  }, [data, searchText, props.filterKeys]);

  React.useImperativeHandle(ref, () => ({
    show: () => {
      setSearchText('');
      setIsVisiable(true);
      const input = document.getElementById('text-field');
      input && input.focus();
    },
    close: () => {
      setIsVisiable(false);
    },
  }));

  const renderItem = useCallback(
    (item, index) => {
      return (
        <div key={index} style={styles.itemStyle}>
          <h3
            style={props.onItemClick && styles.pointer}
            onClick={() => props.onItemClick && props.onItemClick(item)}>
            {item.name || item.body_part_name}
          </h3>
          {props.onRemoveClick && (
            <img
              src="https://cdn-icons-png.flaticon.com/512/1828/1828843.png"
              alt={'remove-icon'}
              width="20px"
              height="20px"
              onClick={() => props.onRemoveClick(item)}
              style={styles.pointer}
            />
          )}
        </div>
      );
    },
    [props],
  );

  return (
    <Modal
      sx={styles.modal}
      open={isVisiable}
      onClose={() => setIsVisiable(false)}>
      <Box sx={styles.searchBox}>
        <Box sx={styles.titleDiv}>
          <div style={styles.title}>{props.title}</div>
          <div style={styles.closeBtn}>
            <img
              src={purpleCrossIcon}
              alt={'cross-icon'}
              width="20px"
              height="20px"
              onClick={() => setIsVisiable(false)}
              style={styles.pointer}
            />
          </div>
        </Box>
        <CommonTextInput
          style={styles.searchInput}
          autoFocus
          label="Search body parts..."
          value={searchText}
          onChange={setSearchText}
        />
        <ListComponent
          data={filteredList}
          renderItem={renderItem}
          emptyTitle="No body parts found"
        />
      </Box>
    </Modal>
  );
};

export default React.forwardRef(SearchBoxModal);

SearchBoxModal.propTypes = {
  onItemClick: PropTypes.func,
  title: PropTypes.string,
  filterKeys: PropTypes.array,
  data: PropTypes.array,
  onRemoveClick: PropTypes.func,
};

SearchBoxModal.defaultProps = {
  title: 'Search',
  filterKeys: ['name'],
};

const styles = {
  modal: {
    display: 'flex',
    justifyContent: 'center',
  },
  searchInput: {
    width: '95%',
    marginTop: '2%',
    marginLeft: '1%',
  },
  searchBox: {
    width: '40%',
    borderRadius: '10px',
    height: '70%',
    marginTop: '5%',
    backgroundColor: 'white',
  },
  titleDiv: {
    display: 'flex',
    position: 'relative',
    paddingTop: '2.5px',
    paddingBottom: '2.5px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  closeBtn: {
    width: '45px',
    height: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 1,
    right: 0,
  },
  itemStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
};
