import React from 'react';
import './subheader.css';
import SyChartButtons from './SyChartButtons';
import Utility from './Utility';

const SubHeader = props => {
  return (
    <div
      className="subheader-container"
      style={{marginTop: Utility.isUhid ? '0px' : '-24px'}}>
      <div
        className="subheader"
        style={{paddingTop: Utility.isUhid ? '0px' : '10px'}}>
        <div className="subheader-btn-div">
          <div className="subheader-btn-container">
            <SyChartButtons />
          </div>
          <div className="subheader-title-div">
            <h2 className="subheader-title">{props.title}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
