import React from 'react';
import './typingIndicator.css';

function TypingIndicator() {
  return (
    <div class="typingIndicatorContainer">
      <div class="typingIndicatorBubble">
        <div class="typingIndicatorBubbleDot"></div>
        <div class="typingIndicatorBubbleDot"></div>
        <div class="typingIndicatorBubbleDot"></div>
      </div>
    </div>
  );
}

export default TypingIndicator;
