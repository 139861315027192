import React from 'react';
import './SychartChatSubHeader.css';
import {ExitPage, Restart} from '../resource/svg';
import {SychartDoctorDefaultProfile} from '../resource/images';
import {useTranslation} from 'react-i18next';
import AlertModal from './AlertModal';
import {useSelector} from 'react-redux';
import {isHtmlDirRtl} from '../../redux/Redcuers/LocaleDirSlice';

const SychartChatSubHeader = props => {
  const [showModal, setShowModal] = React.useState(false);
  const [showExitModal, setExitModal] = React.useState(false);
  const {t} = useTranslation();
  const isDirRtl = useSelector(isHtmlDirRtl);

  const exitSychart = () => {
    const params = {event: 'exit_sychart'};
    window.parent.postMessage(params, '*');
  };

  return (
    <div className="sychartSubHeader">
      <div
        style={{backgroundColor: props.platformPrimaryColor}}
        className="subheaderFeature">
        <div className="subheader-discription ">
          {/* <div onClick={() => setShowModal(true)}>
            <BackSmallArrowSvg className="previousIcon" />
          </div> */}
          <img
            src={SychartDoctorDefaultProfile}
            alt="profile"
            className="subheader-doctor-profile"
          />
          <div className="doctor-status">
            <div className="doctor-name">{t('doctor')}</div>
            <div className="status">
              {!!props?.showQuestion ? t('online') : t('typing')}
            </div>
          </div>
        </div>
        <div style={{display: 'flex', gap: '25px'}}>
          <div
            className="restart-btn-header"
            onClick={() => setShowModal(true)}>
            <Restart style={{transform: isDirRtl ? 'scaleX(-1)' : 'none'}} />
            {t('restart')}
          </div>
          <div className="exit-btn-header" onClick={() => setExitModal(true)}>
            <ExitPage style={{transform: isDirRtl ? 'scaleX(-1)' : 'none'}} />
            {t('exit')}
          </div>
        </div>

        {/* <div className="header-tool">{renderBulb()}</div> */}
      </div>
      <AlertModal
        hideCross
        alert
        visible={showModal}
        close={() => setShowModal(false)}
        text={t('start_over_alert_message')}
        header={t('start_over_sy_chart')}
      />
      <AlertModal
        hideCross
        alert
        visible={showExitModal}
        close={() => setExitModal(false)}
        text={t('are_you_sure_you_want_to_exit_sychart')}
        header={t('exit_sy_chart')}
        onConfirm={exitSychart}
      />
    </div>
  );
};

export default SychartChatSubHeader;
