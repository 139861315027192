import React from 'react';

function MediaUploadProgress(props) {
  const { progress, uploaded, length } = props;

  const showProgress = () => {
    return (
      <div
        className="modalBG"
        style={{
          position: 'fixed',
          bottom: '0px',
          right: '0px',
          left: 0,
          top: 0,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className="cardDiv">
          <div className="percent">
            <svg>
              <circle cx="105" cy="105" r="100"></circle>
              <circle cx="105" cy="105" r="100" style={{ '--percent': progress }}></circle>
            </svg>
            <div className="number">
              <h3>
                {progress}
                <span>%</span>
              </h3>
            </div>
          </div>
          <div className="title">
            <h2>
              {uploaded}/{length}
            </h2>
          </div>
        </div>
      </div>
    );
  };

  return showProgress();
}

export default MediaUploadProgress;
