import React from 'react';
import PropTypes from 'prop-types';
import './commonbutton.css';

export const CommonButton = props => {
  return (
    <button
      {...props}
      disabled={props.loading}
      onClick={props.onClick}
      className={`commonbtn commonbutton ${
        props.loading ? 'commonbutton--loading' : ''
      } pointer`}>
      <span class="commonbutton__text">{props.label}</span>
    </button>
  );
};

export default CommonButton;

CommonButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};
